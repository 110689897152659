import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
    * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    }


    body {
    font-family: "Source Sans Pro", Arial, sans-serif;
    }
`;
