import { Faqleft } from "./faqleft";
import { FaqRight } from "./faqRight";

export const FaqContainer = () => {
  return (
    <div>
      <Faqleft />
      <FaqRight />
    </div>
  );
};
